<template>
  <DataList
    :resource="resource"
    :headers="headers"
    title="Поставки"
    :disable-adding="false"
    :compact-headers="compactHeaders"
    :add-route="{ name: 'shipping-new-steps' }"
    store="shippings"
    :filter-options="filter"
    :disable-compact-mode="false"
  />
</template>

<script>
import DataList from "../../components/DataList.vue";

export default {
  components: {
    DataList,
  },
  data() {
    return {
      resource: "shippings",
      headers: [
        { text: "Дата создания", sortable: true, type: "date", name: "date", width: "30", maxLength: 10, dataPath: "date" },
        { text: "Номер Поставки", sortable: true, type: "link", name: "number", width: "100", maxLength: 10, dataPath: "number" },
        { text: "Внутренний номер заявки", sortable: true, name: "internalRequestNumber", width: "80", maxLength: 9, dataPath: "internalRequestNumber" },
        {
          text: "Статус",
          sortable: true,
          type: "object",
          name: "state",
          width: "110",
          maxLength: 12,
          valueFn(i) {
            return i.state;
          },
        },
        { text: "Менеджер", sortable: true, type: "object", name: "manager", width: "120", maxLength: 8, dataPath: "manager.title" },
        {
          text: "Продавец",
          sortable: true,
          type: "object",
          name: "senderFactory",
          width: "110",
          maxLength: 10,
          valueFn(i) {
            return i.senderFactory;
          },
        },
        {
          text: "Заезд на фабрику",
          sortable: true,
          name: "arrivalFactory",
          width: "100",
          maxLength: 10,
          valueFn(i) {
            return {
              arrivalFactoryDate: i.arrivalFactoryDate,
              vehicle: i.vehicle,
            };
          },
        },
        { text: "Адрес погрузки", sortable: false, name: "loadingAddress", type: "object", width: "110", maxLength: 9, dataPath: "loadingAddress.title" },
        { text: "Производитель", sortable: false, name: "manufacturer", type: "array", width: "100", maxLength: 10 },
        { text: "Категория", sortable: false, name: "productCategories", type: "array", width: "100", maxLength: 10 },
        { text: "Объем поставки, ㎥", sortable: false, name: "volume", maxLength: 10, dataPath: "volume" },
        { text: "Вес поставки, кг", sortable: false, name: "weight", maxLength: 10, dataPath: "weight" },
        {
          text: "Дата доставки план / факт",
          sortable: false,
          type: "planFactDates",
          width: "100",
          maxLength: 10,
          valueFn(i) {
            return {
              fact: i.deliveryToUnloadingPlaceDateFact,
              plan: i.deliveryToUnloadingPlaceDatePlan,
            };
          },
        },
        { text: "Адрес выгрузки", sortable: false, name: "unloadingAddress", type: "object", width: "100", maxLength: 8, dataPath: "unloadingAddress.title" },
        { text: "Остаток на складе, ㎥", sortable: false, name: "warehouseKievVolume", maxLength: 10, dataPath: "warehouseKievVolume" },
        { text: "Даты отгрузок", sortable: false, name: "releaseOrderDates", type: "dateArray", width: "100", maxLength: 10 },
        { text: "", sortable: false, type: "action", width: "130" },
      ],
      compactHeaders: [
        { text: "Дата создания", sortable: true, type: "date", name: "date", width: "30", maxLength: 10, dataPath: "date" },
        { text: "Номер Поставки", sortable: true, type: "link", name: "number", width: "100", maxLength: 10, dataPath: "number" },
        { text: "Внутренний номер заявки", sortable: true, name: "internalRequestNumber", width: "80", maxLength: 9, dataPath: "internalRequestNumber" },
        { text: "Статус", sortable: true, type: "object", name: "state", width: "110", maxLength: 12, dataPath: "state.title" },
        { text: "Менеджер", sortable: true, type: "object", name: "manager", width: "120", maxLength: 8, dataPath: "manager.title" },
        { text: "Продавец", sortable: true, type: "object", name: "senderFactory", width: "100", maxLength: 9, dataPath: "senderFactory.title" },
        {
          text: "Заезд на фабрику",
          sortable: true,
          name: "arrivalFactory",
          width: "100",
          maxLength: 10,
          valueFn(i) {
            return {
              arrivalFactoryDate: i.arrivalFactoryDate,
              vehicle: i.vehicle,
            };
          },
        },
        { text: "Адрес погрузки", sortable: false, name: "loadingAddress", type: "object", width: "90", maxLength: 9, dataPath: "loadingAddress.title" },
        { text: "Объем поставки, ㎥", sortable: false, name: "volume", maxLength: 10, dataPath: "volume" },
        { text: "Вес поставки, кг", sortable: false, name: "weight", maxLength: 10, dataPath: "weight" },
        {
          text: "Дата доставки план / факт",
          sortable: false,
          type: "planFactDates",
          width: "100",
          maxLength: 10,
          valueFn(i) {
            return {
              fact: i.deliveryToUnloadingPlaceDateFact,
              plan: i.deliveryToUnloadingPlaceDatePlan,
            };
          },
        },
        { text: "Адрес выгрузки", sortable: false, name: "unloadingAddress", type: "object", width: "100", maxLength: 8, dataPath: "unloadingAddress.title" },
        { text: "", sortable: false, type: "action", width: "130" },
      ],
      filter: {
        date: {
          title: "Дата",
          type: "date",
          isManualEdit: true,
        },
        number: {
          title: "Номер Поставки",
          type: "text",
        },
        internalRequestNumber: {
          title: "Внутренний номер заявки",
          type: "text",
        },
        states: {
          title: "Статус",
          type: "enum",
          resource: "shipping-states",
        },
        senderFactory: {
          title: "Продавец",
          type: "reference",
          resource: "factories",
        },
        loadingAddress: {
          title: "Адрес погрузки",
          type: "reference",
          resource: "addresses",
          name: "address",
        },
        manufacturer: {
          title: "Производитель",
          type: "text",
        },
        productCategories: {
          title: "Категория",
          type: "text",
        },
        deliveryToUnloadingPlaceDatePlan: {
          title: "Дата доставки план",
          type: "date",
          isManualEdit: true,
        },
        deliveryToUnloadingPlaceDateFact: {
          title: "Дата доставки факт",
          type: "date",
          isManualEdit: true,
        },
        unloadingAddress: {
          title: "Адрес выгрузки",
          type: "reference",
          resource: "addresses",
          name: "address",
        },
        warehouseKievVolume: {
          title: "Остаток на складе",
          type: "decimal",
        },
        releaseOrderDates: {
          title: "Даты отгрузок",
          type: "date",
        },
      },
    };
  },
};
</script>
